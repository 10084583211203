import React from "react"
import { connect } from "react-redux"

const RegistrationStepper = props => {
  const stepperStyle = step =>
    `col-sm-6 col-lg-4 ${props.step === step ? `ui-tabs-active` : ``}`
  return (
    <div className="content-wrap" style={{ paddingBottom: 0 }}>
      <div id="processTabs" data-plugin="tabs" className="customjs">
        <ul className="process-steps row col-mb-30" style={{ margin: 0 }}>
          <li className={stepperStyle(1)}>
            <a href="#ptab1" className="i-circled i-bordered i-alt mx-auto">
              1
            </a>
            <h5>Personal Information</h5>
          </li>
          <li className={stepperStyle(2)}>
            <a href="#ptab2" className="i-circled i-bordered i-alt mx-auto">
              2
            </a>
            <h5>Event Details</h5>
          </li>
          <li className={stepperStyle(3)}>
            <a href="#ptab3" className="i-circled i-bordered i-alt mx-auto">
              3
            </a>
            <h5>Overview and Confirmation</h5>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default connect()(RegistrationStepper)

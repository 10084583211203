import React from "react"
import { Form, DatePicker, Input, Modal, Button } from "antd"
import { connect } from "react-redux"
import { REGISTER } from "../../redux/actionTypes"

class RegistrationFirst extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      password: "",
      fullname: "",
      birthplace: "",
      birthdate: "",
      gender: "",
      phone: "",
      occupation: "",
      institution: "",
      nationality: "",
      country: "",
      province: "",
      city: "",
      currency: "",
      confirmPassword: "",
      errors: {},
      isValidationModal: false,
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleDatePicker = this.handleDatePicker.bind(this)
    this.handleNextButton = this.handleNextButton.bind(this)
  }
  handleDismissModal = () => {
    this.setState({
      isValidationModal: false,
    })
  }
  handlerValidation() {
    let errors = {}
    let formIsValid = true
    let errorEmpty = "cannot be empty"
    let errorEmptySelection = "you have to select one"
    let fullname = this.state.fullname
    let email = this.state.email
    let password = this.state.password
    let confirmPassword = this.state.confirmPassword
    let gender = this.state.gender
    let phone = this.state.phone
    let occupation = this.state.occupation
    let institution = this.state.institution
    let nationality = this.state.nationality
    let country = this.state.country
    let currency = this.state.currency

    // FULLNAME
    if (fullname.trim() === "") {
      formIsValid = false
      errors["Full Name"] = errorEmpty
    }

    // EMAIL
    if (typeof email !== "undefined") {
      let lastAtPos = email.lastIndexOf("@")
      let lastDotPos = email.lastIndexOf(".")

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          email.indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          email.length - lastDotPos > 2
        )
      ) {
        formIsValid = false
        errors["Email"] = "email is not valid"
      }
    }

    if (email.trim() === "") {
      formIsValid = false
      errors["Email"] = errorEmpty
    }

    // PASSWORD & CONFIRM PASSWORD
    if (password.trim() === "") {
      formIsValid = false
      errors["Password"] = errorEmpty
    } else if (password.length < 8) {
      formIsValid = false
      errors["Password"] = "password must contain at least 8 characters"
    }

    if (password !== confirmPassword) {
      formIsValid = false
      errors["Password"] = "password confirmation does not match"
      errors["ConfirmPassword"] = "confirm password confirmation does not match"
    }

    // GENDER
    if (gender.trim() === "") {
      formIsValid = false
      errors["Gender"] = errorEmptySelection
    }

    // PHONE
    if (phone.trim() === "") {
      formIsValid = false
      errors["Phone"] = errorEmpty
    }

    // OCCUPATION
    if (occupation.trim() === "") {
      formIsValid = false
      errors["Occupation"] = errorEmpty
    }

    // INSTITUTION
    if (institution.trim() === "") {
      formIsValid = false
      errors["Institution"] = errorEmpty
    }

    // NATIONALITY
    if (nationality.trim() === "") {
      formIsValid = false
      errors["Nationality"] = errorEmpty
    }

    // COUNTRY
    if (country.trim() === "") {
      formIsValid = false
      errors["Country"] = errorEmpty
    }

    // CURRENCY
    if (currency.trim() === "") {
      formIsValid = false
      errors["Currency"] = errorEmptySelection
    }

    this.setState({ errors: errors })
    return formIsValid
  }
  handleInputChange(event) {
    const target = event.target
    this.setState({ [target.name]: target.value })
  }
  handleDatePicker(moment, string) {
    this.setState({ birthdate: moment.format(`YYYY-MM-DD`) })
  }
  async handleNextButton(event) {
    event.preventDefault()
    if (this.handlerValidation()) {
      const payload = { form: this.state }
      this.props.handleStepper(this.props.step + 1)
      this.props.dispatch({ type: REGISTER, payload })
    } else {
      this.setState({
        isValidationModal: true,
      })
    }
  }
  render() {
    const gender = this.state.gender
    const valueMale = "male"
    const valueFemale = "female"
    const isMale = gender === valueMale ? `active` : ``
    const isFemale = gender === valueFemale ? `active` : ``

    const currency = this.state.currency
    const valueIDR = "IDR"
    const valueUSD = "USD"
    const isIDR = currency === valueIDR ? `active` : ``
    const isUSD = currency === valueUSD ? `active` : ``

    const errorsList = this.state.errors

    return (
      <div style={{ display: this.props.step === 1 ? "unset" : "none" }}>
        <div className="container">
          <div id="ptab1">
            <div className="row">
              <div className="col-lg-8">
                <div className="form-widget">
                  <div className="form-result" />
                  <div className="form-process">
                    <div className="css3-spinner">
                      <div className="css3-spinner-scaler" />
                    </div>
                  </div>
                  <div className="col-12 form-group mb-4">
                    <label htmlFor="full-name">
                      Full Name (as shown in certificate)
                    </label>
                    <input
                      type="text"
                      name="fullname"
                      id="fullname"
                      className="form-control required"
                      placeholder="Enter your Full Name"
                      value={this.state.fullname}
                      onChange={this.handleInputChange}
                    />
                    <span style={{ color: "red" }}>
                      {this.state.errors["Full Name"]}
                    </span>
                  </div>
                  <div className="col-12 form-group mb-4">
                    <label htmlFor="email">E-mail</label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="form-control required"
                      placeholder="yourname@mail.com"
                      value={this.state.email}
                      onChange={this.handleInputChange}
                    />
                    <span style={{ color: "red" }}>
                      {this.state.errors["Email"]}
                    </span>
                  </div>
                  <div className="col-12 form-group mb-4">
                    <label htmlFor="password">Password</label>
                    <Form.Item
                      rules={[
                        { required: true, message: "Enter your Password!" },
                      ]}
                    >
                      <Input.Password
                        placeholder="Enter your Password"
                        name="password"
                        value={this.state.password}
                        onChange={this.handleInputChange}
                      />
                    </Form.Item>
                    <span style={{ color: "red" }}>
                      {this.state.errors["Password"]}
                    </span>
                  </div>
                  <div className="col-12 form-group mb-4">
                    <label htmlFor="confirmpassword">Confirm Password</label>
                    <Form.Item
                      rules={[
                        { required: true, message: "Confirm Your Password!" },
                      ]}
                    >
                      <Input.Password
                        placeholder="Confirm Your Password"
                        name="confirmPassword"
                        value={this.state.confirmPassword}
                        onChange={this.handleInputChange}
                      />
                    </Form.Item>
                    <span style={{ color: "red" }}>
                      {this.state.errors["Confirm Password"]}
                    </span>
                  </div>
                  <div className="col-12 form-group mb-4">
                    <label htmlFor="gender-type" className="mb-3">
                      Gender
                    </label>
                    <br />
                    <div
                      className="btn-group btn-group-toggle flex-wrap"
                      data-toggle="buttons"
                      style={{ width: "100%" }}
                    >
                      <button
                        name="gender"
                        value={valueMale}
                        onClick={this.handleInputChange}
                        className={
                          "btn btn-outline-secondary px-3 font-weight-semibold ls0 nott " +
                          isMale
                        }
                      >
                        Male
                      </button>
                      <button
                        name="gender"
                        value={valueFemale}
                        onClick={this.handleInputChange}
                        className={
                          "btn btn-outline-secondary px-3 font-weight-semibold ls0 nott " +
                          isFemale
                        }
                      >
                        Female
                      </button>
                    </div>
                    <span style={{ color: "red" }}>
                      {this.state.errors["Gender"]}
                    </span>
                  </div>
                  <div className="col-12 form-group mb-4">
                    <label htmlFor="phone">Phone Number</label>
                    <input
                      type="number"
                      name="phone"
                      id="phone"
                      className="form-control required"
                      placeholder="081234567890"
                      value={this.state.phone}
                      onChange={this.handleInputChange}
                    />
                    <span style={{ color: "red" }}>
                      {this.state.errors["Phone"]}
                    </span>
                  </div>
                  <div className="col-12 form-group mb-4">
                    <label htmlFor="occupation">Occupation</label>
                    <input
                      type="text"
                      name="occupation"
                      id="occupation"
                      className="form-control required"
                      placeholder="Researcher"
                      value={this.state.occupation}
                      onChange={this.handleInputChange}
                    />
                    <span style={{ color: "red" }}>
                      {this.state.errors["Occupation"]}
                    </span>
                  </div>
                  <div className="col-12 form-group mb-4">
                    <label htmlFor="institution">Institution</label>
                    <input
                      type="text"
                      name="institution"
                      id="institution"
                      className="form-control required"
                      placeholder="Institution Name"
                      value={this.state.institution}
                      onChange={this.handleInputChange}
                    />
                    <span style={{ color: "red" }}>
                      {this.state.errors["Institution"]}
                    </span>
                  </div>
                  <div className="col-12 form-group mb-4">
                    <label htmlFor="nationality">Nationality</label>
                    <input
                      type="text"
                      name="nationality"
                      id="nationality"
                      className="form-control required"
                      placeholder="Indonesia"
                      value={this.state.nationality}
                      onChange={this.handleInputChange}
                    />
                    <span style={{ color: "red" }}>
                      {this.state.errors["Nationality"]}
                    </span>
                  </div>
                  <div className="col-12 form-group mb-4">
                    <label htmlFor="phone">Country</label>
                    <input
                      type="text"
                      name="country"
                      id="country"
                      className="form-control required"
                      placeholder="Indonesia"
                      value={this.state.country}
                      onChange={this.handleInputChange}
                    />
                    <span style={{ color: "red" }}>
                      {this.state.errors["Country"]}
                    </span>
                  </div>
                  <div className="col-md-6 form-group mb-4">
                    <label htmlFor="phone">Province (Optional)</label>
                    <input
                      type="text"
                      name="province"
                      id="province"
                      className="form-control"
                      placeholder="East Java"
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div className="col-md-6 form-group mb-4">
                    <label htmlFor="phone">City (Optional)</label>
                    <input
                      type="text"
                      name="city"
                      id="city"
                      className="form-control"
                      placeholder="Surabaya"
                      value={this.state.city}
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div className="col-md-6 form-group mb-4">
                    <label htmlFor="birthplace">
                      Place of Birth (Optional)
                    </label>
                    <input
                      type="text"
                      name="birthplace"
                      id="birthplace"
                      className="form-control required"
                      placeholder="Surabaya"
                      value={this.state.birthplace}
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div className="col-md-6 form-group mb-4">
                    <label htmlFor="date-of-birth">
                      Date of Birth (Optional)
                    </label>
                    <div className="input-group">
                      <DatePicker
                        name="birthdate"
                        onChange={this.handleDatePicker}
                        className="form-control dobpicker"
                      />
                    </div>
                  </div>
                  <div className="col-12 form-group mb-4">
                    <label htmlFor="currency-type" className="mb-3">
                      Currency
                    </label>
                    <br />
                    <div
                      className="btn-group btn-group-toggle flex-wrap"
                      data-toggle="buttons"
                      style={{ width: "100%" }}
                    >
                      <button
                        name="currency"
                        value={valueIDR}
                        onClick={this.handleInputChange}
                        className={
                          `btn btn-outline-secondary px-3 font-weight-semibold ls0 nott ` +
                          isIDR
                        }
                      >
                        IDR (Indonesia)
                      </button>
                      <button
                        name="currency"
                        value={valueUSD}
                        onClick={this.handleInputChange}
                        className={
                          `btn btn-outline-secondary px-3 font-weight-semibold ls0 nott ` +
                          isUSD
                        }
                      >
                        USD (International)
                      </button>
                    </div>
                    <span style={{ color: "red" }}>
                      {this.state.errors["Currency"]}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 mb-4">
                <div className="line line-sm mb-4" />
                <button
                  className="button button-reg button-3d button-circle gradient-grey-orange float-right tab-linker"
                  onClick={this.handleNextButton}
                >
                  Next: Event Details &rArr;
                </button>
                <Modal
                  visible={this.state.isValidationModal}
                  title="Form Validation"
                  onCancel={this.handleDismissModal}
                  footer={[
                    <Button key="back" onClick={this.handleDismissModal}>
                      Confirm
                    </Button>,
                  ]}
                >
                  {Object.keys(errorsList).map((item, i) => {
                    return (
                      <p className="modal-size mb-0">
                        {item} - {errorsList[item]}
                      </p>
                    )
                  })}
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect()(RegistrationFirst)

import React from "react"
import { Modal, Button } from "antd"
import { connect } from "react-redux"
import { Users, Purchases } from "../../redux/api"
import { navigate } from "gatsby"
import { LOGIN } from "../../redux/actionTypes"
import { PROFILE } from "../../constants/routes"
import { formatWithCurrency } from "../../utils/currency"

class RegistrationThird extends React.Component {
  constructor(props) {
    super(props)
    this.state = { loading: false, visible: false }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handlePrevButton = this.handlePrevButton.bind(this)
    this.getItemDetailById = this.getItemDetailById.bind(this)
    this.getTotalPrice = this.getTotalPrice.bind(this)
    this.getPrice = this.getPrice.bind(this)
    this.getCurrency = this.getCurrency.bind(this)
  }

  async handleSubmit(e) {
    this.setState({ loading: true })
    const form = this.props.state.user.form
    const registered = await Users.register(form)
    this.setState({ loading: false })
    if (!registered) return this.setState({ error: true }) // error register
    this.props.dispatch({ type: LOGIN, payload: await registered.json() })

    const items = this.props.state.item.cart
    const currency = this.getCurrency()
    const purchased = await Purchases.initiate({ currency, items })
    if (!purchased) return this.setState({ error: true }) // error purchase
    navigate(PROFILE)
  }
  handlePrevButton() {
    this.props.handleStepper(this.props.step - 1)
  }
  getItemDetailById(id) {
    const filtered = this.props.state.item.catalog.filter(c => c.id === id)
    return filtered ? filtered[0] : {}
  }
  getCurrency() {
    const { form } = this.props.state.user
    return form ? form.currency : null
  }
  getPrice(item) {
    if (this.getCurrency() === "USD") return item.price_usd
    if (this.getCurrency() === "IDR") return item.price_idr
    return null
  }
  getTotalPrice() {
    return this.props.state.item.cart.reduce((a, c) => {
      return a + this.getPrice(this.getItemDetailById(c))
    }, 0)
  }

  state = {
    ModalText: "Content of the modal",
    visible: false,
    confirmLoading: false,
  }

  showModal = () => {
    this.setState({
      loading: false,
      visible: true,
    })
  }

  handleCancel = () => {
    console.log("Clicked cancel button")
    this.setState({
      visible: false,
    })
  }

  render() {
    const { visible } = this.state

    return (
      <div
        className="content-wrap"
        style={{ display: this.props.step === 3 ? "unset" : "none" }}
      >
        <div className="container">
          <div id="ptab3">
            <div className="table-responsive mb-0">
              <table className="table">
                <thead>
                  <tr>
                    <th className="cart-product-name">Category</th>
                    <th className="cart-product-name">Type</th>
                    <th className="cart-product-name min-amount">Total</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.state.item.cart.map((item, i) => {
                    const detail = this.getItemDetailById(item)
                    return (
                      <tr key={i} className="cart_item">
                        <td className="cart-product-name">
                          <h4
                            className="amount-shade"
                            style={{ textTransform: "uppercase" }}
                          >
                            {detail.category}
                          </h4>{" "}
                        </td>
                        <td className="cart-product-name">
                          <h4 className="amount-shade">{detail.name}</h4>{" "}
                        </td>
                        <td className="cart-product-name">
                          <h4
                            className="amount-number"
                            style={{ textTransform: "uppercase" }}
                          >
                            {formatWithCurrency(
                              this.getPrice(detail),
                              this.getCurrency()
                            )}
                          </h4>
                        </td>
                      </tr>
                    )
                  })}

                  <tr className="cart_item">
                    <td
                      className="cart-product-name"
                      style={{ borderTop: "3px solid #b2b2b2" }}
                    ></td>
                    <td
                      className="cart-product-name"
                      style={{ borderTop: "3px solid #b2b2b2" }}
                    >
                      <h3>Total</h3>
                    </td>
                    <td
                      className="cart-product-name"
                      style={{ borderTop: "3px solid #b2b2b2" }}
                    >
                      <h3 className="amount-shade">Rp</h3>
                      <h3 className="amount-number color-apastd">
                        {formatWithCurrency(
                          this.getTotalPrice(),
                          this.getCurrency()
                        )}
                      </h3>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="col-lg-12 mb-5">
              <div className="line line-sm mb-6 mt-0"></div>
              <button
                className="button button-reg button-3d button-circle gradient-grey-orange tab-linker"
                onClick={this.handlePrevButton}
              >
                Previous
              </button>

              <Button
                type="primary"
                onClick={this.showModal}
                className="button button-reg button-3d button-circle gradient-grey-orange float-right tab-linker"
              >
                Confirm Registration
              </Button>
              <Modal
                visible={visible}
                title="Confirm Registration"
                onCancel={this.handleCancel}
                footer={[
                  <Button key="back" onClick={this.handleCancel}>
                    Return
                  </Button>,
                  <Button
                    key="submit"
                    type="primary"
                    loading={this.state.loading}
                    onClick={this.handleSubmit}
                  >
                    Confirm
                  </Button>,
                ]}
              >
                <p className="modal-size mb-0">
                  Are you sure with your data and your preference event?
                </p>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return { state }
}

export default connect(mapStateToProps)(RegistrationThird)

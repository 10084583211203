import React from "react"
import { Modal, Button } from "antd"
import { connect } from "react-redux"
import { Items } from "../../redux/api"
import { SAVE_CATALOG, ADD_TO_CART } from "../../redux/actionTypes"
import { formatWithCurrency } from "../../utils/currency"

class RegistrationSecond extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      catalog: { items: { participant: [], day1: [], day2: [], workshop: [] } },
      participant: null,
      day1: null,
      day2: null,
      workshop: null,
      errors: {},
      isValidationModal: false,
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this)
    this.handlePrevButton = this.handlePrevButton.bind(this)
    this.handleNextButton = this.handleNextButton.bind(this)
    this.getPrice = this.getPrice.bind(this)
    this.getCurrency = this.getCurrency.bind(this)
  }
  handleDismissModal = () => {
    this.setState({
      isValidationModal: false,
    })
  }
  handlerValidation() {
    let errors = {}
    let formIsValid = true
    let errorEmptySelection = "you have to select one"
    let participant = this.state.participant
    let day1 = this.state.day1
    let day2 = this.state.day2

    // PARTICIPANT
    if (!participant) {
      formIsValid = false
      errors["Participant"] = errorEmptySelection
    }

    // DAY1
    if (!day1) {
      formIsValid = false
      errors["Day 1"] = errorEmptySelection
    }

    // DAY2
    if (!day2) {
      formIsValid = false
      errors["Day 2"] = errorEmptySelection
    }

    this.setState({ errors: errors })
    return formIsValid
  }
  async componentDidMount() {
    const res = await Items.catalog()
    const catalog = res ? await res.json() : null
    if (!catalog) return
    const workshop = new Array(catalog.items.workshop.length).fill(null)
    this.props.dispatch({ type: SAVE_CATALOG, payload: catalog })
    this.setState({ catalog, workshop })
  }
  handleInputChange(event) {
    const target = event.target
    this.setState({ [target.name]: target.value })
  }
  handleCheckboxChange(event) {
    const target = event.target
    const i = target.value.split(",")[0]
    const v = target.value.split(",")[1]
    const workshop = [...this.state.workshop]
    workshop[i] = workshop[i] ? null : v
    this.setState({ workshop })
  }
  handlePrevButton() {
    this.props.handleStepper(this.props.step - 1)
  }
  handleNextButton() {
    if (this.handlerValidation()) {
      const { participant, day1, day2, workshop } = this.state
      const payload = [participant, day1, day2, ...workshop]
        .filter(i => i != null)
        .map(i => parseInt(i))
      this.props.handleStepper(this.props.step + 1)
      this.props.dispatch({ type: ADD_TO_CART, payload })
    } else {
      this.setState({
        isValidationModal: true,
      })
    }
  }
  getCurrency() {
    const { form } = this.props.state.user
    return form ? form.currency : null
  }
  getPrice(item) {
    if (this.getCurrency() === "USD") return item.price_usd
    if (this.getCurrency() === "IDR") return item.price_idr
    return null
  }
  render() {
    const errorsList = this.state.errors

    return (
      <div
        className="content-wrap"
        style={{ display: this.props.step === 2 ? "unset" : "none" }}
      >
        <div className="container">
          <div id="ptab2">
            <div className="row">
              <div className="col-lg-8">
                <div className="form-widget">
                  <div className="form-result"></div>

                  <div className="form-process">
                    <div className="css3-spinner">
                      <div className="css3-spinner-scaler"></div>
                    </div>
                  </div>

                  <div className="col-12 alert alert-info mb-5">
                    <h3 className="info mb-0">
                      <i className="icon-info-circle"></i>All Symposium
                    </h3>
                    <br />
                    <p className="mb-0">
                      You are eligible to attend all symposium events.
                    </p>
                  </div>

                  <div className="col-12 form-group mb-4">
                    <label htmlFor="participant-category" className="mb-3">
                      Participant Category
                    </label>
                    <br />
                    <div
                      className="btn-group-trg btn-group-toggle"
                      data-toggle="buttons"
                    >
                      {this.state.catalog.items.participant.map((item, i) => {
                        return (
                          <button
                            key={i}
                            className={
                              `btn btn-outline-secondary px-3 font-weight-semibold ls0 nott ` +
                              (parseInt(this.state.participant) ===
                              parseInt(item.id)
                                ? `active`
                                : ``)
                            }
                            name="participant"
                            value={item.id}
                            onClick={this.handleInputChange}
                          >
                            {item.name}
                            <br />
                            {formatWithCurrency(
                              this.getPrice(item),
                              this.getCurrency()
                            )}
                          </button>
                        )
                      })}
                    </div>
                    <span style={{ color: "red" }}>
                      {this.state.errors["Participant"]}
                    </span>
                  </div>

                  <div className="col-12 form-group mb-4">
                    <label htmlFor="day1" className="mb-3">
                      Day 1
                    </label>
                    <br />
                    <div className="btn-group-trg btn-group-toggle">
                      {this.state.catalog.items.day1.map((item, i) => {
                        return (
                          <button
                            key={i}
                            className={
                              `btn btn-outline-secondary px-3 font-weight-semibold ls0 nott ` +
                              (parseInt(this.state.day1) === parseInt(item.id)
                                ? `active`
                                : ``)
                            }
                            name="day1"
                            value={item.id}
                            onClick={this.handleInputChange}
                          >
                            {item.name}
                          </button>
                        )
                      })}
                    </div>
                    <span style={{ color: "red" }}>
                      {this.state.errors["Day 1"]}
                    </span>
                  </div>

                  <div className="col-12 form-group mb-4">
                    <label htmlFor="day2" className="mb-3">
                      Day 2
                    </label>
                    <br />
                    <div className="btn-group-trg btn-group-toggle">
                      {this.state.catalog.items.day2.map((item, i) => {
                        return (
                          <button
                            key={i}
                            className={
                              `btn btn-outline-secondary px-3 font-weight-semibold ls0 nott ` +
                              (parseInt(this.state.day2) === parseInt(item.id)
                                ? `active`
                                : ``)
                            }
                            name="day2"
                            value={item.id}
                            onClick={this.handleInputChange}
                          >
                            {item.name}
                          </button>
                        )
                      })}
                    </div>
                    <span style={{ color: "red" }}>
                      {this.state.errors["Day 2"]}
                    </span>
                  </div>

                  <div className="col-12 form-group mb-4">
                    <label htmlFor="website-cost-type" className="mb-3">
                      Workshop (Optional)
                    </label>
                    <br />

                    {this.state.catalog.items.workshop.map((item, i) => {
                      return item.id !== 10 ? (
                        <div key={i} className="form-check mb-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={i + `,` + item.id}
                            onChange={this.handleCheckboxChange}
                          />
                          <label
                            className="form-check-label label-check-input"
                            style={{ cursor: "default" }}
                          >
                            {item.name}
                            <br />
                            {formatWithCurrency(
                              this.getPrice(item),
                              this.getCurrency()
                            )}
                          </label>
                        </div>
                      ) : null
                    })}
                  </div>
                </div>
              </div>
              <div className="col-lg-12 mb-4">
                <div className="line line-sm mb-4"></div>
                <button
                  className="button button-reg button-3d button-circle gradient-grey-orange tab-linker"
                  rel="1"
                  onClick={this.handlePrevButton}
                >
                  Previous
                </button>
                <button
                  className="button button-reg button-3d button-circle gradient-grey-orange float-right tab-linker"
                  rel="3"
                  onClick={this.handleNextButton}
                >
                  Overview
                </button>
                <Modal
                  visible={this.state.isValidationModal}
                  title="Form Validation"
                  onCancel={this.handleDismissModal}
                  footer={[
                    <Button key="back" onClick={this.handleDismissModal}>
                      Confirm
                    </Button>,
                  ]}
                >
                  {Object.keys(errorsList).map((item, i) => {
                    return (
                      <p className="modal-size mb-0">
                        {item} - {errorsList[item]}
                      </p>
                    )
                  })}
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(state => ({ state }))(RegistrationSecond)

import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/banner"
import RegistrationStepper from "../components/part/registrationStepper"
import RegistrationFirst from "../components/part/registrationFirst"
import RegistrationSecond from "../components/part/registrationSecond"
import RegistrationThird from "../components/part/registrationThird"

const RegisterPage = () => {
  const [getStep, setStep] = useState(1)
  const handleStepper = updateStep => {
    setStep(updateStep)
  }

  return (
    <Layout>
      <SEO title="Register" />
      <Banner title="Registration"></Banner>
      <RegistrationStepper step={getStep}></RegistrationStepper>
      <RegistrationFirst
        handleStepper={handleStepper}
        step={getStep}
      ></RegistrationFirst>
      <RegistrationSecond
        handleStepper={handleStepper}
        step={getStep}
      ></RegistrationSecond>
      <RegistrationThird
        handleStepper={handleStepper}
        step={getStep}
      ></RegistrationThird>
    </Layout>
  )
}

export default RegisterPage
